import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import HeroRrhh from "./Section/HeroRrhh";
import BloqueTextoFormulario from "./Section/BloqueTextoFormulario";
import Formulario from "./Section/Formulario";

const RRHH = () => {
  useMetaTags({
    title: "RRHH - Delsud",
    description: "Vos también    podés formar parte    de nuestro equipo Completá el formulario del área en la que te gustaría trabajar. Si tenemos algún puesto disponible para tu perfil, nos vamos a poner en contacto. Ventas Administración Arquitectura Periodismo Finanzas Marketing &amp; Diseño Ventas Administración Arquitectura Periodismo Finanzas Marketing &amp; Diseño Ventas Administración Arquitectura Periodismo &hellip; RRHH Leer más &raquo;",
  });

  return (
    <>
      <HeroRrhh />
      <BloqueTextoFormulario />
      <Formulario />
    </>
  );
};

export default RRHH;
