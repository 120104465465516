import React from "react";
import { useResponsive } from "../../Hooks/useResponsive";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import FlipBoxes from "./sections/FlipBoxes/FlipBoxes";
import LastNews from "./sections/LastNews/LastNews";
import TextBox from "./sections/TextBox/TextBox";
import Companies from "./sections/Companies/Companies";

export default function Home() {
  const isMobile = useResponsive();

  useMetaTags({
    title: "Delsud",
    description:
      "Potenciamos el desarrollo nacional Ver video completo Integridad Nuestro equipo de trabajo está formado por profesionales altamente calificados que confían, apuestan y se identifican con los proyectos que generamos. Responsabilidad Social Somos conscientes del déficit que tiene nuestro país en distintos sectores. Por eso, como empresa, creemos que todos merecen tener la oportunidad de alcanzar &hellip; Home Leer más &raquo;",
  });

  return (
    <div
      style={{
        backgroundImage:
          "url(https://grupodelsud.com/wp-content/uploads/2021/04/Grupo-385.png)",
      }}
    >
      <Header />
      <FlipBoxes />
      <LastNews />
      <TextBox />
      <Companies />
    </div>
  );
}
