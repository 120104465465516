import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import style from "./styles/empresas.module.css";
import Header from "./sections/Header/Header";
import CompanyAccordion from "./sections/Accordion/Accordion";
import Slider from "./sections/Slider/Slider";

export default function Empresas() {
  useMetaTags({
    title: "Empresas - Delsud",
    description: "Nuestras empresas Ofrecemos un servicio completo, controlando cada proceso. Creamos oportunidades de desarrollo y crecimiento  Accedé a tu terreno de forma rápida y con la mejor financiación. Somos una de las empresas desarrolladoras urbanísticas más completas y eficientes del mercado. Visitá la web Nuestra misión es que cada familia pueda cumplir el sueño de tener &hellip; Empresas Leer más &raquo;",
  });

  return (
    <div className={style.container}>
      <Header />
      <CompanyAccordion />
      <Slider />
    </div>
  );
}
