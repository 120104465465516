import React, { useState } from "react";
import style from "../../styles/drawer.module.css";
import { Link } from "react-router-dom";
import LogoWidget from "../LogoWidget/LogoWidget";
import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";

import burger from "../../images/burgerMenu.svg";

const item = (name, link, onClickFunction) => {
  return (
    <ListItem key={name} className={style.item} onClick={onClickFunction}>
      <ListItemText className={style.textItem}>
        <Link to={link}>{name}</Link>
      </ListItemText>
    </ListItem>
  );
};

export default function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  const pages = [
    { name: "Nosotros", link: "/Nosotros" },
    { name: "Novedades", link: "/Novedades" },
    { name: "Empresas", link: "/Empresas" },
    { name: "RRHH", link: "/RRHH" },
    { name: "Contacto", link: "/Contacto" },
  ];

  return (
    <div className={style.container}>
      <Drawer
        className={style.drawer}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {pages.map((page) =>
            item(page.name, page.link, () => setOpenDrawer(false))
          )}
        </List>
      </Drawer>

      <div
        className={style.burgerCont}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <img src={burger} alt="menu-opciones" />
      </div>

      <div className={style.logo}>
        <Link to="/">
          <LogoWidget />
        </Link>
      </div>
    </div>
  );
}
