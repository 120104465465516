import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import Form from "./sections/Form/Form";

export default function Contacto() {
  useMetaTags({
    title: "Contacto - Delsud",
    description:
      "Dejanos tu mensaje Dejanos tu mensaje Nombre y Apellido: Mail: Mensaje: Nombre y Apellido: Mail: Mensaje:",
  });

  return (
    <div>
      <Header />
      <Form />
    </div>
  );
}
