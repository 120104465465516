import React from "react";
import style from "../../styles/header.module.css";

import arrow from "../../images/arrow.svg";
//import backgroundVideo from "../../images/bgVideo.mp4";

export default function Header() {
  function scrollDown() {
    window.scroll({
      top: 970,
      left: 970,
      behavior: "smooth",
    });
  }

  return (
    <section className={style.sectionContainer}>
      <div className={style.videoContainer}>
        <video autoPlay muted loop className={style.bgVideo}>
          <source
            src={
              "https://eleditorplatense.s3.amazonaws.com/Delsud-website/video-home.webm"
            }
            type="video/mp4"
          />
        </video>
      </div>
      <div className={style.container}>
        <div className={style.hero}>
          <div>
            <p className={style.title}>
              Potenciamos
              <br />
              el desarrollo
              <br />
              nacional
            </p>
          </div>
          {/*<div className={style.button}>
            <a
              href="https://www.youtube.com/watch?v=W0uG8AZnvZA&ab_channel=GrupoDelsud"
              target="_blank"
              id="hovereable"
            >
              Ver video completo
            </a>
          </div>*/}
          <div className={style.arrowButton}>
            <a onClick={scrollDown}>
              <img src={arrow} id="hovereable" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
