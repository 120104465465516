import React from 'react'
import '../Styles/bloqueTextoFormulario.css'

//React Icon
import { BiRightArrowAlt } from 'react-icons/bi';


export default function BloqueTextoFormulario() {
  return (
    <div className='bloque-texto-rrhh-container'>
        <div className='formulario-boxs-text'>
            <div className='formulario-box-blue'>
                <BiRightArrowAlt/>
                <p> Vos también<br/> podés formar parte de nuestro equipo</p>
            </div>
            <div className='formulario-box-white'>
                <p>Completá el formulario del área en la que te gustaría trabajar.</p>
                <p>Si tenemos algún puesto disponible para tu perfil, nos vamos a poner en contacto.</p>
             </div>
        </div>
    </div>
  )
}
