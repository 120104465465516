import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import NovedadesAccordion from "./sections/Accordion/Accordion";

export default function Novedades() {
  useMetaTags({
    title: "Novedades - Delsud",
    description: "Desarrollos en Mendoza. Desarrollos Delsud comenzará muy pronto sus operaciones en Cuyo, en los departamentos de Maipú y Guaymallén &hellip; Novedades Leer más &raquo;",
  });

  return (
    <div>
      <Header />
      <NovedadesAccordion />
    </div>
  );
}
