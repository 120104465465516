import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import Filosofia from "./sections/Filosofia/Filosofia";
import Historia from "./sections/Historia/Historia";
import Contador from "./sections/Contador/Contador";
import Sumate from "./sections/Sumate/Sumate";

export default function Nosotros() {
  useMetaTags({
    title: "Nosotros - Delsud",
    description: "&nbsp; Filosofía &nbsp; &nbsp; de Gestión &nbsp; &nbsp; Empresarial Todo lo que tenés que saber sobre la empresa Visión Buscamos satisfacer las necesidades y superar las expectativas de quienes nos eligen, siendo además una empresa que apuesta a la formación, desarrollo y crecimiento de todos los que son parte de nuestros proyectos. Detrás de nuestro &hellip; Nosotros Leer más &raquo;",
  });

  return (
    <div
      style={{
        backgroundImage:
          "url(https://grupodelsud.com/wp-content/uploads/2021/04/Grupo-385.png)",
      }}
    >
      <Header />
      <Filosofia />
      <Historia />
      <Contador />
      <Sumate />
    </div>
  );
}
