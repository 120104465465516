import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import style from "./styles/postulacionExito.module.css";

export default function PostulacionExito() {
  useMetaTags({
    title: "Contacto_Exitoso_RRHH - Delsud",
    description: "¡Excelente!Tu solicitud fue enviada. Evaluaremos tu perfily nos pondremos en contacto. Gracias por elegirnos.",
  });

  return (
    <div className={style.container}>
      <div className={style.modal}>
        <div className={style.title}>
          <h3>
            ¡Excelente!
            <br />
            Tu solicitud fue enviada.
          </h3>
        </div>

        <p>
          Evaluaremos tu perfil
          <br />y nos pondremos en contacto.
        </p>
        <p>Gracias por elegirnos.</p>
      </div>
    </div>
  );
}
