import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import style from "./styles/contactoExito.module.css";

export default function ContactoExito() {
  useMetaTags({
    title: "Contacto_Exitoso - Delsud",
    description: "¡Gracias!Tu mensaje fue enviado. En caso de ser pertinente, recibirás una respuestani bien podamos abrir tu mensaje.",
  });

  return (
    <div className={style.container}>
      <div className={style.modal}>
        <div className={style.title}>
          <h3>
            ¡Gracias!
            <br />
            Tu mensaje fue enviado.
          </h3>
        </div>

        <p>
          En caso de ser pertinente, recibirás una respuesta
          <br />
          en la brevedad.
        </p>
      </div>
    </div>
  );
}
